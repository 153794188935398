import Header from "./components/Header/Header";
import "./sass/main.scss";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Concept from "./components/Notre concept/Concept";
import Menu from "./components/LaCarte/Menu";
import Contact from "./components/Contact/Contact";
import Event from "./components/Evenement/Event";
import Galerie from "./components/Galerie/Galerie";
import NotFound from "./components/NotFound/NotFound";
import History from "./components/History/History";
import SideInfos from "./components/SideInfos";
import Legal from "./components/Legal/Legal";
import Rgpd from "./components/Legal/Rgpd";
import Producteur from "./components/Producteurs/Producteur";
import Activity from "./components/Activity/Activity";
function App() {
  return (
    <div className="App">
      <Header />
      <SideInfos />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/concept" element={<Concept />} />
        <Route path="/carte" element={<Menu />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/event" element={<Event />} /> */}
        <Route path="/galerie" element={<Galerie />} />
        <Route path="/activite-associations" element={<Activity />} />
        <Route path="/producteurs" element={<Producteur />} />
        <Route path="/notre-histoire" element={<History />} />
        <Route path="/mention-legales" element={<Legal />} />
        <Route path="/rgpd" element={<Rgpd />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
// nVm67WapJU5qc8J8 ftp mdp