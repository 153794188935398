import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../../../sass/main.scss";
function MHeader() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  let active = open ? " show " : "mobile_nav";
  let icone = open ? (
    <MdClose className="emoji" />
  ) : (
    <GiHamburgerMenu className="emoji" />
  );
  return (
    <div className="container_header">
      <div className="header_flex">
        <div className="header_menu item">
          <div className="container_emoji" onClick={() => setOpen(!open)}>
            {icone}
          </div>
          <div
            className="container_logo item"
            onClick={() => {
              navigate("/");
            }}
          >
            <p>LA COOP</p>
            {/* <img src={Logo} alt="logo" className="logo" /> */}
          </div>
          <div className="header_reseau  item">
            <a
              href="https://www.facebook.com/profile.php?id=100087671659726"
              target="blank"
              className="header-link"
            >
              <AiFillFacebook className="emoji" />
            </a>
            <a
              href="https://instagram.com/barlacoopboissy?igshid=YmMyMTA2M2Y="
              target="blank"
              className="header-link"
            >
              <AiFillInstagram className="emoji" />
            </a>
          </div>
        </div>
        <nav className={`${active}`}>
          <ul className="header_list">
            <li>
              <NavLink
                className="hearder_items"
                to="/"
                onClick={() => setOpen(!open)}
              >
                <span>Accueil</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="hearder_items"
                to="concept"
                onClick={() => setOpen(!open)}
              >
                <span>Concept</span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                className="hearder_items"
                to="carte"
                onClick={() => setOpen(!open)}
              >
                <span>Carte</span>
              </NavLink>
            </li> */}
            <li>
              <NavLink
                className="hearder_items"
                to="galerie"
                onClick={() => setOpen(!open)}
              >
                <span>Galerie</span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                className="hearder_items"
                to="event"
                onClick={() => setOpen(!open)}
              >
                <span>Évenements</span>
              </NavLink>
            </li> */}
            <li>
              <NavLink
                className="hearder_items"
                to="activite-associations"
                onClick={() => setOpen(!open)}
              >
                <span>Activités et Associations</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                className="hearder_items"
                to="producteurs"
                onClick={() => setOpen(!open)}
              >
                <span>Producteurs</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="hearder_items"
                to="notre-histoire"
                onClick={() => setOpen(!open)}
              >
                <span>Histoire</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="hearder_items"
                to="contact"
                onClick={() => setOpen(!open)}
              >
                <span>Contact</span>
              </NavLink>
            </li>
          </ul>
          <div className="bg_header" onClick={() => setOpen(!open)}></div>
        </nav>
      </div>
    </div>
  );
}

export default MHeader;
